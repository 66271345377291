/**
 * Initialize page transition animations using anime.js
 * Docs: https://animejs.com/documentation
 */

import anime from '../vendor/anime.min'
import Utils from './utils'

export default class Animations {
  constructor() {
    // Cache
    this.$body = document.body
    this.$header = document.querySelector('.header-main')
    this.$menuContainer = document.getElementById('menu-main-container')
    this.$menuContainerInner = document.getElementById('menu-main-container-inner')
    this.$menu = document.getElementById('menu-main')
    this.$menuItems = this.$menu.querySelectorAll('.swiper-slide.intro-visible')
    this.$menuWrapper = this.$menu.querySelector('.swiper-wrapper')
    this.$pageBg = document.getElementById('page-background')
    this.$screenIntro = document.getElementById('screen-intro')

    // Config
    this.config = {
      isBigScreen: Utils.testBigScreen(),
      bounceEasing: 'cubicBezier(.66, -0.3, .22, 1)',
      inOutEasing: 'easeInOutCubic',
      wordIntroDuration: 600, // How long does a single word in the homepage intro appear before switching?
      pageChangeDuration: 700, // Page / menu animations on mobile
      wordSlideInDuration: 900 // Main menu items intro animation
    }
  }

  // Big screen page change
  pageChange($fromPage, $toPage, halfWayHandler = null, finishHandler = null) {
    const animationDuration = 700
    const fromMenu = $fromPage.getAttribute('data-page') === 'home'
    const toMenu = $toPage.getAttribute('data-page') === 'home'

    // Do we animate from the menu (home page)?
    if (fromMenu === true) {
      // Make sure to keep the menu visible
      this.$body.classList.add('menu-visible')

      if (typeof halfWayHandler === 'function') {
        halfWayHandler()
      }

      // Outer menu container to top, and inner container to bottom
      anime({
        targets: [this.$menuContainer, this.$menuContainerInner],
        translateY: function(el, i, l) {
          // Container: -100%, inner container: 100%
          return [0, (i === 0 ? '-100%' : '100%')]
        },
        duration: animationDuration,
        easing: this.config.inOutEasing,
        complete: () => {
          // Hide the menu again
          this.$body.classList.remove('menu-visible')

          // Reset
          this.$menuContainer.style.transform = null
          this.$menuContainerInner.style.transform = null

          this.$menuContainerInner.classList.remove('animate-down')

          if (typeof finishHandler === 'function') {
            finishHandler()
          }
        }
      })

      // Fade in the header & slide in the content after
      anime({
        targets: this.$header,
        opacity: [0, 1],
        duration: animationDuration,
        delay: animationDuration,
        easing: this.config.inOutEasing,
      })

      const $pageContent = document.querySelector('.page.active .page-content')
      anime({
        targets: $pageContent,
        translateY: ['100vh', 0],
        duration: animationDuration,
        delay: animationDuration / 1.5,
        easing: this.config.inOutEasing,
        complete: () => {
          // Reset
          $pageContent.style.translateY = null
        }
      })
    } else {
      // Move to a page / menu from a page

      // Make sure to make the page not scrollable and scrolled to the top while animating
      this.$body.classList.add('lock')
      this.$body.classList.remove('over-scroll', 'over-menu-scroll')

      // Page background from bottom to top
      anime({
        targets: this.$pageBg,
        translateY: ['100vh', 0],
        duration: animationDuration,
        easing: this.config.inOutEasing,
        complete: () => {
          if (typeof halfWayHandler === 'function') {
            halfWayHandler()
          }

          if (toMenu === true) {
            this.$body.classList.add('menu-visible')
            this.$menuContainer.classList.add('inactive')
          } else {
            // Scroll to top
            //window.scrollTo(0,0)
          }

          // Make sure the new page is already visible, but in the background
          $toPage.classList.add('inactive')

          // And make sure the old page is still in the picture
          $fromPage.classList.add('active-fake')

          // Move page up
          const $pageInner = $fromPage.querySelector('.page-inner')
          anime({
            targets: [$fromPage, this.$pageBg, $pageInner],
            translateY: function(el, i, l) {
              // $fromPage & this.$pageBg: -100vh, $pageInner: 100vh
              return [0, i < 2 ? '-100vh' : '100vh']
            },
            duration: animationDuration,
            easing: this.config.inOutEasing,
            complete: () => {
              // Reset
              $fromPage.style.transform = null
              $pageInner.style.transform = null
              $fromPage.classList.remove('active-fake')
              $toPage.classList.remove('inactive')
              this.$pageBg.style.transform = null
              this.$body.classList.remove('lock')

              if (toMenu === true) {
                this.$menuContainer.classList.remove('inactive')
              }

              if (typeof finishHandler === 'function') {
                finishHandler(true)
              }
            }
          })
        }
      })
    }
  }

  // Homepage words intro
  wordsIntro(finishHandler = null) {
    const $words = this.$screenIntro.querySelectorAll(`.intro-frame${this.config.isBigScreen ? ':not(.visible-mobile)' : ''}`)
    //const $sectionContent = document.querySelector('.section-content')
    //const $activeMenuItem = this.$menu.querySelector('.intro-active')

    let tl = anime.timeline({
      easing: 'linear',
      duration: this.config.wordIntroDuration,
      complete: () => {
        // All words have been shown and hidden, now show the menu
        this.$menuContainer.classList.add('visible')

        // Set the x position of the main menu so that the first item is centered
        if (this.config.isBigScreen) {
          this.$screenIntro.remove();

          const menuCenterX = (this.$menu.offsetWidth / 2) - (this.$menuItems[0].offsetWidth / 2)
          anime({
            targets: this.$menuWrapper,
            translateX: `${menuCenterX}px`,
            duration: 0,
          })
        }

        if (typeof finishHandler === 'function') {
          finishHandler()
        }
      }
    })

    $words.forEach(($word, i) => {
      tl.add({
        targets: $word,
        begin: () => {
          // Show
          $word.classList.add('visible')
        },
        complete: () => {
          // Hide again it it's not the last one and we are on mobile
          if (i === ($words.length - 1)) {
            return
          }
          $word.classList.remove('visible')
        }
      })
    })
  }

  showHeaderFooter(finishHandler = null) {
    anime({
      targets: '.header-main, .footer-main',
      opacity: 1,
      duration: 400,
      easing: 'linear',
      complete: () => {
        if (typeof finishHandler === 'function') {
          finishHandler()
        }
      }
    })
  }

  // Animate main menu items from right to left after the main intro animation (not visible on mobile)
  largeScreenIntro(finishHandler = null) {
    let tl = anime.timeline({
      easing: this.config.bounceEasing,
      duration: this.config.wordSlideInDuration,
      complete: () => {
        // Make the menu scrollable
        this.$menu.classList.remove('scroll-lock')

        // Remove first Honest. item and recompensate layout
        const firstSlideWidth = this.$menuItems[0].offsetWidth
        anime({
          targets: this.$menuWrapper,
          translateX: `+=${firstSlideWidth}px`,
          duration: 0,
        })
        this.$menuItems[0].remove()

        if (typeof finishHandler === 'function') {
          finishHandler()
        }
      }
    })

    // And then animate the words of the menu
    const paddingX = parseInt(this.$menuWrapper.style.transform.replace(/[^0-9\-.,]/g, ''))
    let doScrollContainer = true
    for (let i = 0; i < this.$menuItems.length; i++) {
      // Scroll the container to center each time new text appears
      if (doScrollContainer && i > 0) {
        let menuItemPosition = (this.$menuItems[i].getBoundingClientRect().left - this.$menu.offsetWidth - paddingX)
        let newCenterPosition = menuItemPosition - (this.$menu.offsetWidth / 2) + (this.$menuItems[i].offsetWidth / 2)

        tl.add({
          targets: this.$menuWrapper,
          translateX: `-${newCenterPosition}px`,
        })
      }

      // And at the same time slide in element
      tl.add({
        targets: this.$menuItems[i],
        translateX: ['100vw', 0],
        complete: () => {
          if (this.$menuItems[i].classList.contains('swiper-slide-active')) {
            // Fade in header and footer when the animating of menu items stops
            this.showHeaderFooter()
          }
        }
      }, `-=${this.config.wordSlideInDuration}`)

      if (doScrollContainer) {
        // Stop at Honest menu item
        doScrollContainer = ! this.$menuItems[i].classList.contains('swiper-slide-active')
      }
    }
  }

  smallScreenIntro(finishHandler = null) {
    anime({
      targets: this.$screenIntro,
      translateX: [0, '-100vw'],
      duration: 700,
      easing: this.config.bounceEasing
    })

    anime({
      targets: this.$menuContainer,
      translateX: ['100vw', 0],
      duration: 700,
      easing: this.config.bounceEasing,
      complete: () => {
        if (typeof finishHandler === 'function') {
          finishHandler()
        }
      }
    })

    this.$menu.classList.remove('scroll-lock')
  }

  // Mobile page change
  toggleMenu(showMenu = null, halfWayHandler = null, finishHandler = null) {
    const navToMenu = showMenu !== null ? showMenu : this.$body.classList.contains('menu-show')
    const $targetContainer = document.querySelector(navToMenu ? '.page.active' : '#menu-main')
    const $targetTopContent = $targetContainer.querySelector(navToMenu ? '.page-inner' : 'a.active:not(.menu-item-large)')
    const $targetBackground = document.querySelector(navToMenu ? '#page-background' : '#menu-main-background')

    // Make sure to make the page not scrollable and scrolled to the top while animating
    this.$body.classList.add('lock')
    this.$body.classList.remove('over-scroll', 'over-menu-scroll')
    this.$menu.classList.remove('scroll-lock')

    // Specific timeline depending on moving to or from the menu
    if (navToMenu === true) {
      if (this.$body.classList.contains('menu-visible')) {
        return
      }

      //this.config.pageChangeDuration = 20000

      // Move the menu background from right to left
      anime({
        targets: $targetBackground,
        translateX: ['100vw', '-100vw'],
        duration: this.config.pageChangeDuration,
        easing: 'linear'
      })

      // After 50% completes, move the menu to the right too
      // And at the same time move the content of the page / menu to the right so it overflows
      anime({
        targets: [$targetContainer, $targetTopContent],
        translateX: function(el, i, l) {
          // Container: -100%, inner container: 100%
          return [0, (i === 0 ? '-100vw' : '100vw')]
        },
        duration: this.config.pageChangeDuration / 2,
        delay: this.config.pageChangeDuration / 2,
        easing: 'linear',
        changeBegin: () => {
          // Trigger
          if (typeof halfWayHandler === 'function') {
            halfWayHandler()
          }

          this.$menuContainer.classList.add('to-background')

          // Scroll to top
          //window.scrollTo(0,0)

          // Reset menu positions
          this.$menu.style.transform = null
          this.$body.classList.add('menu-visible')

          const $activeLink = this.$menu.querySelector('a.active')
          if ($activeLink) {
            $activeLink.style.transform = null
          }
        },
        complete: () => {
          // Trigger
          if (typeof finishHandler === 'function') {
            finishHandler()
          }

          $targetBackground.style.transform = null
          $targetTopContent.style.transform = null

          this.$body.classList.remove('lock')
          this.$menuContainer.classList.remove('to-background')
        }
      })
    } else {
      // Nav from menu
      this.$body.classList.remove('menu-show')

      // Move the menu background from left to right
      anime({
        targets: $targetBackground,
        translateX: ['-100vw', 0],
        duration: this.config.pageChangeDuration / 2,
        easing: 'linear'
      })

      // After 50% completes, move the menu to the right too
      // And at the same time move the content of the page / menu to the right so it overflows
      anime({
        targets: [$targetContainer, $targetTopContent],
        translateX: function(el, i, l) {
          // Container: -100%, inner container: 100%
          return [0, (i === 0 ? '100vw' : '-100vw')]
        },
        duration: this.config.pageChangeDuration / 2,
        delay: this.config.pageChangeDuration / 2,
        easing: 'linear',
        changeBegin: () => {
          // Trigger
          if (typeof halfWayHandler === 'function') {
            halfWayHandler()
          }

          // Show page again
          document.querySelector('.page.active').style.transform = null

          // Hide all navigation links except the active one
          this.$menu.classList.add('hidden-links')
        },
        complete: () => {
          // Trigger
          if (typeof finishHandler === 'function') {
            finishHandler()
          }

          $targetBackground.style.transform = null
          $targetContainer.style.transform = null
          $targetTopContent.style.transform = null

          this.$body.classList.remove('lock')
          this.$body.classList.remove('menu-visible')
          this.$menu.classList.remove('hidden-links')
        }
      })
    }
  }
}
