import page from './vendor/page.min.js'
import Utils from './modules/utils'
import Animations from './modules/animations'
import Sliders from './modules/sliders'

/**
 * Cache DOM objects and global variables
 */
const $body = document.body
const $menu = document.getElementById('menu-main')
const isBigScreen = Utils.testBigScreen()
const animations = new Animations()
const sliders = new Sliders()

/**
 * Define global router for all internal url changes
 */
page('*', (e) => {
  // Trim trailing slashes from begin and end of current url path
  let cleanPath = e.path.replace(/^\/\/*/, '').replace(/\/\/*$/, '')
  cleanPath = cleanPath === '' ? 'home' : cleanPath

  const firstPageLoad = typeof e.init !== 'undefined' && e.init

  // Check if given page exists by checking DOM from url param, and if not show the 404 page
  let $newPage = document.querySelector(`[data-page="${cleanPath}"]`)
  if (! $newPage) {
    $newPage = document.querySelector('[data-page="404"]')
    cleanPath = '404'
  }

  const $oldPage = document.querySelector('[data-page].active')
  const oldPath = $oldPage ? $oldPage.getAttribute('data-page') : null

  if (oldPath === cleanPath) {
    // Don't do anything if we stay on the same page
    return
  }

  const introAnimation = Utils.getCookie('__intro_shown') !== '1'

  // Update body class to new page so the page-specific styling is updated to page-specific colors
  $body.className = document.body.className.replace(/page-loaded-([^\s;]+)?/, '')
  $body.classList.add(`page-loaded-${cleanPath}`)

  // Set current page navigation item to active
  document.querySelectorAll('a[data-nav].active').forEach($link => {
    $link.classList.remove('active')
  })
  document.querySelectorAll(`a[data-nav="${cleanPath}"]`).forEach($link => {
    $link.classList.add('active')
  })

  // Make sure to not show the animation intro again when navigating between pages
  $body.classList.remove('animation-intro')

  if (cleanPath === 'home') {
    if (firstPageLoad) {
      $body.classList.add('menu-visible')
    }

    if (! introAnimation) {
      $body.classList.remove('animation-intro')
      $menu.classList.remove('scroll-lock')

      if (isBigScreen === true) {
        // Inmediately display all elements without animation
        $body.classList.add('skip-animation')
      }
    }
  }

  // Hide the first fake slide that is meant for the intro animation
  if (! introAnimation && $menu.querySelector('.intro-first')) {
    $menu.querySelector('.swiper-wrapper').removeChild($menu.querySelector('.intro-first'))
  }

  // If e.init = true, then this is the first website load, and will only be triggered once
  if (firstPageLoad) {
    activatePage($newPage)

    $body.classList.add('page-loaded')

    // Only show intro animation on page load and home page
    if (cleanPath === 'home') {
      if (introAnimation) {
        // Don't display the animation intro for a week
        Utils.setCookie('__intro_shown', '1', (24 * 7))

        $body.classList.add('animation-intro')

        // Start intro animation
        animations.wordsIntro(() => {
          // Animation finished
          if (isBigScreen === true) {
            setTimeout(() => {
              animations.largeScreenIntro(() => {
                // When remove the animation intro class, we need to recalculate the x position because new
                // elements are now visible and they shift the scroll position
                $body.classList.remove('animation-intro')
                $body.classList.add('skip-animation') // To show the header & footer
                sliders.initMainMenuSwiper()
              })
            }, 500)
          } else {
            animations.smallScreenIntro(() => {
              $body.classList.remove('animation-intro')
            })
          }
        })
      } else {
        if (isBigScreen === true) {
          // Trigger swiper directly if first page visit is home
          sliders.initMainMenuSwiper()
        }
      }
    } else {
      // If direct page visit
      setTimeout(() => {
        sliders.initFooterSwiper()
      }, 50)
    }

    // Apply mailto link href's
    const email = process.env.EMAIL_TO
    document.querySelectorAll('[data-mailto]').forEach(($link) => {
      let mailtoType = $link.getAttribute('data-mailto')
      let subject = ''
      // Wtf? Dynamic key for process.env doesn't work
      if (mailtoType === 'coffee') {
        subject = process.env['EMAIL_SUBJECT_COFFEE']
      } else if (mailtoType === 'lunch') {
        subject = process.env['EMAIL_SUBJECT_LUNCH']
      } else if (mailtoType === 'canaltrip') {
        subject = process.env['EMAIL_SUBJECT_CANALTRIP']
      }

      $link.setAttribute('href', `mailto:${email}?subject=${encodeURIComponent(subject)}`)
    })
  } else {
    // Page change animations

    if (isBigScreen === true) {
      $menu.classList.add('scroll-lock')

      animations.pageChange(
        $oldPage,
        $newPage,
        () => {
          activatePage($newPage)

          if (cleanPath === 'home') {
            // Make sure menu slider is rendered correctly
            setTimeout(() => {
              sliders.initMainMenuSwiper()
            }, 50);
          }
        },
        () => {
          $menu.classList.remove('scroll-lock')

          if ($oldPage) {
            $oldPage.classList.remove('active')
          }
          sliders.initFooterSwiper()
        }
      )
    } else {
      // Mobile
      animations.toggleMenu(
        cleanPath === 'home',
        () => {
          if (cleanPath !== 'home') {
            activatePage($newPage)
          }
        },
        () => {
          if (cleanPath === 'home') {
            activatePage($newPage)
          }
        }
      )
    }
  }
})

function activatePage($page) {
  // Set the old page to inactive / invisible
  const $oldPage = document.querySelector('[data-page].active')
  if ($oldPage) {
    $oldPage.classList.remove('active')
  }

  // Set new page active and init stuff
  $page.classList.add('active')

  // Initiate all content sliders on the new page
  sliders.initContentSlider($page)
}

/**
 * Initialize Page.js router
 */
page.start()

/**
 * Hide or show the mobile menu
 */
const $toggleMenu = document.querySelector('[data-toggle-menu]')
$toggleMenu.onclick = $toggleMenu.ontap = (e) => {
  $body.classList.toggle('menu-show')

  animations.toggleMenu()
}

/**
 * Hover over main menu items to change styling
 */
let removeTimeout = null
Utils.live('mouseover', 'a[data-nav]', (e) => {
  if (removeTimeout) {
    clearTimeout(removeTimeout)
  }
  $menu.setAttribute('data-active', e.originalTarget.getAttribute('data-nav'))
})

Utils.live('mouseout', 'a[data-nav]', (e) => {
  removeTimeout = setTimeout(() => {
    $menu.removeAttribute('data-active')
  }, 300)
})

/**
 * Prevent nav clicking when body is animating / locked
 */
const $navLinks = document.querySelectorAll('a[data-nav]')
$navLinks.forEach($link => {
  $link.onclick = $link.ontap = (e) => {
    if ($body.classList.contains('lock')) {
      e.preventDefault()
      e.stopPropagation()
      return false
    }
  }
})


/**
 * Toggle class depending on the scroll position to determine the color of h1's on pages
 */
const scrollYPpadding = 10 // After how much % of the window height do we initiate the class?
const minY = window.outerHeight * (scrollYPpadding / 100)
let maxY

window.addEventListener('scroll', (e) => {
  const posY = window.pageYOffset || document.documentElement.scrollTop

  if (isBigScreen === false) {
    return;
  }

  if (posY > minY) {
    $body.classList.add('over-scroll')
  } else {
    $body.classList.remove('over-scroll')
  }

  if (! $body.classList.contains('lock')) {
    maxY = $body.scrollHeight - (window.innerHeight * 1.5) // Hide menu if menu is reached
    if (posY > maxY) {
      $body.classList.add('over-menu-scroll')
    } else {
      $body.classList.remove('over-menu-scroll')
    }
  }
})
