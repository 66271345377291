export default class Utils {
  /**
   * Test if the current screen size is above a certain value, determined in a media query in CSS
   */
  static testBigScreen() {
    const $testElement = document.getElementById('big-screen-test')
    const style = window.getComputedStyle($testElement)

    return style.getPropertyValue('top') === '1px'
  }

  /**
   * Cookie utils
   */
  static setCookie(key, value, hours) {
    let expires = ''

    if (hours) {
      const date = new Date()
      date.setTime(date.getTime() + (hours * 60 * 60 * 1000))
      expires = `; expires=${date.toUTCString()}`
    }

    document.cookie = `${key}=${(value || ``)}${expires}; path=/`
  }

  static getCookie(key) {
    let nameEQ = `${key}=`;
    let ca = document.cookie.split(';')

    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];

      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length)
      }

      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length)
      }
    }

    return null;
  }

  // Emulate jQuery's awesome live() event binding function
  static live(eventType, elementQuerySelector, cb) {
    document.addEventListener(eventType, event => {
      const qs = document.querySelectorAll(elementQuerySelector)

      if (qs) {
        let el = event.target
        let index
        while (el && ((index = Array.prototype.indexOf.call(qs, el)) === -1)) {
          el = el.parentElement
        }

        if (index > -1) {
          event.originalTarget = qs[index]
          cb.call(el, event)
        }
      }
    });
  }
}
