import Swiper from '../vendor/swiper.min'

export default class Sliders {
  constructor () {
    // Cache
    this.$body = document.body
    this.$menu = document.getElementById('menu-main')

    // Config
    this.defaultSwiperSettings = {
      width: window.outerWidth,
      speed: 400,
      centeredSlides: true,
      centerInsufficientSlides: true,
      slidesPerView: 'auto',
      grabCursor: true,
      freeMode: true,
      freeModeSticky: true,
      freeModeMomentumRatio: 0.35,
      freeModeMinimumVelocity: 0.2,
      freeModeMomentumBounce: true,
      freeModeMomentumBounceRatio: 2,
      mousewheel: true,
      roundLengths: true,
      keyboard: {
        enabled: true,
      },
    }

    // State
    this.isTyping = false
    this.typingTimeouts = []
  }

  // Swiper.js lib init
  initFooterSwiper() {
    // Find the active slide based on the current page
    const $activeSlide = document.querySelector('#menu-bottom .menu-item.active')
    let extraOptions = {}

    if ($activeSlide) {
      extraOptions = {
        initialSlide: [...$activeSlide.parentElement.children].indexOf($activeSlide),
      }
    }

    return new Swiper(
      '#pages-bottom',
      Object.assign(this._getDefaultSwiperSettings(), extraOptions),
    )
  }

  initMainMenuSwiper() {
    const _this = this

    if (this.$menu.classList.contains('swiper-container-initialized')) {
      return
    }

    // Because font rendering is slow, make sure to set the width or else the centering will fail
    const $menuItems = this.$menu.querySelectorAll('.swiper-slide')
    $menuItems.forEach(($item) => {
      $item.style.width = `${$item.offsetWidth}px`
    })

    const $activeSlide = this.$menu.querySelector('.swiper-slide[data-active]')
    return new Swiper(
      this.$menu,
      Object.assign(this._getDefaultSwiperSettings(), {
        loop: true,
        loopedSlides: 999999,
        noSwipingClass: 'scroll-lock',
        initialSlide: [...$activeSlide.parentElement.children].indexOf($activeSlide),
        on: {
          slideChange: function() {
            const $newSlide = _this.$menu.querySelectorAll('.swiper-slide')[this.activeIndex]
            const isSameSlide = this.activeIndex === this.previousIndex

            if ($newSlide) {
              if (_this.isTyping === false || ! isSameSlide) {
                _this.typeText($newSlide.getAttribute('data-typetext'))
                _this.isTyping = true
              }

              // Set active state of the menu for the per-page styling
              //_this.$menu.setAttribute('data-active', $newSlide.getAttribute('data-nav'))
            }
          },
          transitionEnd: function() {
            _this.$menu.classList.remove('is-moving')
          },
          sliderMove: function() {
            _this.$menu.classList.add('is-moving')
          },
        },
      })
    )
  }

  initContentSlider($page) {
    // Find the active slide based on the current page
    const $slider = $page.querySelector('.content-slider')

    if (! $slider || $slider.classList.contains('swiper-container-initialized')) {
      return
    }

    const $activeSlide = $slider.querySelector('.swiper-slide.active')
    const pageWidth = (window.outerWidth - ($slider.getBoundingClientRect().left * 2))
    let extraOptions = {
      width: Math.min($slider.offsetWidth, pageWidth),
      mousewheel: {
        forceToAxis: true
      }
    }

    if ($activeSlide) {
      extraOptions.initialSlide = [...$activeSlide.parentElement.children].indexOf($activeSlide)
    }

    return new Swiper(
      $slider,
      Object.assign(this._getDefaultSwiperSettings(), extraOptions),
    )
  }

  // Simulate typing text in the footer
  typeText(text) {
    const $typeContainer = document.querySelector('[data-current-page]')
    const typingSpeed = 130 // ms between strokes

    // Make sure the blinking cursor appears
    $typeContainer.classList.add('active')

    // First clear previous timeouts
    this.typingTimeouts.forEach((t, i) => {
      if (t) {
        clearTimeout(t)
      }
    })
    this.typingTimeouts = []

    $typeContainer.textContent = ''
    text = ` — ${text}` // Prefix a dash
    for (let i = 0; i < text.length; i++) {
      let letter = text.charAt(i)

      this.typingTimeouts.push(setTimeout(() => {
          $typeContainer.textContent = `${$typeContainer.textContent}${letter}`

        if (i === text.length) {
          this.isTyping = false
        }
      }, i * typingSpeed))
    }
  }

  // Add multiple event listeners in a single function call
  _addEventListeners($element, events, fn) {
    events.split(' ').forEach(e => $element.addEventListener(e, fn, false))
  }

  // Return default settings without reference. Disgusting little trick
  _getDefaultSwiperSettings() {
    return JSON.parse(JSON.stringify(this.defaultSwiperSettings))
  }
}
